import React from 'react';
import { useNavigate } from 'react-router-dom';

export default function Categories() {
    const navigate = useNavigate();

    const handleButtonClick = () => {
        navigate('/productpage');
    };
    return (
        <div>
            
        </div>
    );
}
