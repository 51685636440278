import React, { useState, useEffect } from "react";
import { assets } from "../assets/assets";
import { Link } from "react-router-dom";

const Hero = () => {
  const images = [assets.img1, assets.img2, assets.img3];
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 4000);
    return () => clearInterval(interval);
  }, [images.length]);

  const features = [
    { img: assets.logo1, text: "Robotics" },
    { img: assets.logo2, text: "Artificial Intelligence (AI)" },
    { img: assets.logo3, text: "Coding" },
    { img: assets.logo6, text: "Medical Skills" },
    { img: assets.logo4, text: "IoT" },
    { img: assets.logo5, text: "Soft Skills" },
    { img: assets.logo7, text: "3D Printing" },
  ];

  return (
    <div>
      {/* Hero Section */}
      <div
        className="relative flex flex-col-reverse lg:flex-row items-center justify-center min-h-screen px-6 md:px-12 lg:px-20"
        style={{
          backgroundImage: `url(${assets.bg})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="absolute inset-0 bg-black bg-opacity-30 z-0"></div>

        {/* Left Content */}
        <div className="relative z-10 text-center lg:text-left text-white max-w-3xl">
          <h1 className="uppercase text-4xl md:text-5xl lg:text-7xl font-bold leading-tight">
            Shaping Young Minds with Practical Skills ──
          </h1>
          <p className="text-lg md:text-xl text-gray-300 mt-4 lg:mt-6">
            Unlocking the potential of students through hands-on learning,
            critical thinking, and innovative skills development—aligned with
            school curricula and beyond.
          </p>

          {/* Buttons */}
          <div className="flex justify-center lg:justify-start gap-4 mt-6">
            <a
              href="/Brochureiice.pdf"
              download="Brochureiice.pdf"
              className="px-6 py-3 bg-teal-500 hover:bg-teal-600 text-white rounded-full shadow-md transition"
            >
              Download PDF
            </a>
            <Link
              to="/ContactUs"
              className="px-6 py-3 bg-white text-teal-600 hover:bg-gray-100 rounded-full shadow-md transition"
            >
              Contact Us
            </Link>
          </div>
        </div>

        {/* Image Carousel */}
        <div className="relative w-full sm:w-2/3 md:w-1/2 lg:w-1/3 flex justify-center items-center mt-10 lg:mt-0">
          <div className="relative w-72 h-72 md:w-80 md:h-80">
            <img
              src={images[currentImageIndex]}
              alt={`carousel-${currentImageIndex}`}
              className="w-full h-full object-cover transition-all duration-1000 rounded-full shadow-xl"
            />
          </div>
          <div className="absolute bottom-4 flex gap-2">
            {images.map((_, index) => (
              <div
                key={index}
                className={`w-3 h-3 rounded-full transition-all duration-500 ${
                  currentImageIndex === index ? "bg-teal-400 w-4 h-4" : "bg-gray-500"
                }`}
              ></div>
            ))}
          </div>
        </div>
      </div>

      {/* Features Marquee Section */}
      <div className="w-full bg-white py-6 overflow-hidden relative">
        <div className="flex whitespace-nowrap animate-marquee">
          {features.concat(features).map((item, index) => (
            <div
              key={index}
              className="flex items-center mx-6 text-center hover:scale-105 transition-transform duration-200"
            >
              <img src={item.img} alt={item.text} className="w-6 h-6 object-contain" />
              <span className="uppercase text-black text-sm ml-2">{item.text}</span>
            </div>
          ))}
        </div>

        <style jsx>{`
          @keyframes marquee {
            from {
              transform: translateX(0);
            }
            to {
              transform: translateX(-50%);
            }
          }
          .animate-marquee {
            display: flex;
            width: max-content;
            animation: marquee 30s linear infinite;
          }
          @media (max-width: 768px) {
            .animate-marquee {
              animation-duration: 15s;
            }
          }
        `}</style>
      </div>
    </div>
  );
};

export default Hero;
