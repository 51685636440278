import React from "react";
import { assets } from "../assets/assets";

const AboutPage = () => {
    const timelineItems = [
        {
            year: "2020",
            title: "Foundation",
            description: "IICE was founded with a vision to transform education.",
        },
        {
            year: "2021",
            title: "Innovation",
            description: "Launched pilot programs introducing hands-on learning.",
        },
        {
            year: "2022",
            title: "Expansion",
            description: "Expanded our curriculum to include AI, robotics, and more.",
        },
        {
            year: "2023",
            title: "Partnerships",
            description: "Collaborated with leading institutions to refine our approach.",
        },
        {
            year: "2024",
            title: "Milestone",
            description: "Reached over 10,000 students and celebrated a key milestone.",
        },
        {
            year: "2025",
            title: "Global Reach",
            description: "Set our sights on nationwide and global expansion.",
        },
    ];

    return (
        <div className="min-h-screen bg-gray-50">
            {/* Hero Section */}
            <div
                className="relative w-full h-80 bg-cover bg-center"
                style={{ backgroundImage: `url(${assets.aboutHero})` }}
            >
                <div className="absolute inset-0 bg-black opacity-40"></div>
                <div className="relative flex items-center justify-center h-full">
                    <h1 className="text-white text-5xl font-bold">About Us</h1>
                </div>
            </div>

            {/* Introduction Section */}
            <div className="container mx-auto px-4 py-16">
                <div className="flex flex-col lg:flex-row items-center gap-8">
                    <div className="lg:w-1/2">
                        <img
                            src={assets.aboutImg1}
                            alt="About Us"
                            className="w-full h-96 object-cover rounded-lg shadow-lg"
                        />
                    </div>
                    <div className="lg:w-1/2 text-gray-800">
                        <h2 className="text-3xl font-bold mb-4">Who We Are</h2>
                        <p className="text-lg leading-relaxed">
                            We are the Indian Institute of Career Education (IICE), dedicated to bridging
                            the gap between traditional academic learning and real-world skills. Our innovative
                            curriculum integrates technology, creativity, and hands-on experience to empower the next
                            generation of leaders.
                        </p>
                    </div>
                </div>
            </div>

            {/* Vision & Mission Section */}
            <div className="bg-gradient-to-r from-teal-500 to-cyan-500 py-16">
                <div className="container mx-auto px-20 text-white">
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                        <div className="flex flex-col justify-center">
                            <h2 className="text-3xl font-bold mb-4">Our Vision</h2>
                            <p className="text-lg leading-relaxed">
                                To revolutionize education by embedding industry-relevant skills into every classroom,
                                nurturing innovation, critical thinking, and creativity.
                            </p>
                        </div>
                        <div className="flex flex-col justify-center">
                            <h2 className="text-3xl font-bold mb-4">Our Mission</h2>
                            <p className="text-lg leading-relaxed">
                                To empower students through a blend of academic rigor and practical skill development,
                                preparing them to excel in the modern world.
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            {/* Timeline Section */}
            <div className="container mx-auto px-4 py-16">
                <h2 className="text-4xl font-bold text-center text-gray-800 mb-12">
                    Our Journey
                </h2>
                <div className="relative">
                    {/* Vertical Center Line */}
                    <div className="absolute left-1/2 transform -translate-x-1/2 h-full border-l-2 border-purple-500"></div>
                    <div className="space-y-8">
                        {timelineItems.map((item, index) => (
                            <div
                                key={index}
                                className={`relative w-full flex ${
                                    index % 2 === 0 ? "justify-end" : "justify-start"
                                }`}
                            >
                                {index % 2 === 0 ? (
                                    <>
                                        <div className="w-1/2"></div>
                                        <div className="z-20 flex items-center bg-purple-500 shadow-xl w-10 h-10 rounded-full">
                                            <h1 className="mx-auto text-white font-semibold">
                                                {item.year}
                                            </h1>
                                        </div>
                                        <div className="bg-purple-100 rounded-lg shadow-xl w-1/2 px-6 py-4">
                                            <h3 className="mb-1 font-bold text-gray-800 text-xl">
                                                {item.title}
                                            </h3>
                                            <p className="text-gray-700 text-sm">{item.description}</p>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <div className="bg-purple-100 rounded-lg shadow-xl w-1/2 px-6 py-4">
                                            <h3 className="mb-1 font-bold text-gray-800 text-xl">
                                                {item.title}
                                            </h3>
                                            <p className="text-gray-700 text-sm">{item.description}</p>
                                        </div>
                                        <div className="z-20 flex items-center bg-purple-500 shadow-xl w-10 h-10 rounded-full">
                                            <h1 className="mx-auto text-white font-semibold">
                                                {item.year}
                                            </h1>
                                        </div>
                                        <div className="w-1/2"></div>
                                    </>
                                )}
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            {/* Call to Action */}
            <div className="bg-teal-600 py-8">
                <div className="container mx-auto text-center text-white">
                    <h2 className="text-3xl font-bold mb-4">Join Us on Our Journey</h2>
                    <p className="text-lg">Discover how IICE is redefining education and shaping the future.</p>
                </div>
            </div>
        </div>
    );
};

export default AboutPage;
