import { useState, useEffect, useRef } from "react";
import logo from "../Images/darklogoiice.png";
import { Link } from "react-router-dom";
import { assets } from "../assets/assets";

const Navbar = () => {
    const [visible, setVisible] = useState(false);
    const [showHeader, setShowHeader] = useState(true);
    const lastScroll = useRef(0);
    const menuRef = useRef(null);

    useEffect(() => {
        const handleScroll = () => {
            const currentScroll = window.scrollY;
            setShowHeader(currentScroll <= lastScroll.current || currentScroll <= 100);
            lastScroll.current = currentScroll;
        };

        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, []);

    // Close menu when clicking outside
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setVisible(false);
            }
        };

        if (visible) {
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }

        return () => document.removeEventListener("mousedown", handleClickOutside);
    }, [visible]);

    // Navigation links
    const navLinks = [
        { name: "Home", path: "/" },
        { name: "Our Vision", path: "/AboutUs" },
        { name: "About Us", path: "/AboutUs" },
        { name: "Contact Us", path: "/ContactUs" }
    ];

    return (
        <div
            className={`px-6 sm:px-4 py-4 flex items-center justify-between font-medium fixed w-full top-0 transition-transform duration-300 z-[100] bg-white shadow-md ${
                showHeader ? "translate-y-0" : "-translate-y-full"
            }`}
        >
            <Link to="/">
                <div className="flex items-center gap-2">
                    {/* Main Logo */}
                    <img src={logo} className="w-14 sm:w-16 md:w-20" alt="IICE Logo" />

                    {/* Text Logo */}
                    <img src={assets.logoname1} className="w-40 sm:w-52 md:w-64" alt="IICE Text Logo" />
                </div>
            </Link>

            {/* Desktop Navigation */}
            <ul className="hidden md:flex space-x-6">
                {navLinks.map((link, index) => (
                    <li key={index}>
                        <Link to={link.path} className="text-black hover:text-gray-800 transition-all">
                            {link.name}
                        </Link>
                    </li>
                ))}
            </ul>

            {/* Mobile Menu Button */}
            <div className="cursor-pointer md:hidden" onClick={() => setVisible(true)}>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6 text-black"
                >
                    <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
                </svg>
            </div>

            {/* Overlay to Close Menu on Click Outside */}
            {visible && <div className="fixed inset-0 bg-black bg-opacity-50 z-[199]" onClick={() => setVisible(false)} />}

            {/* Mobile Menu */}
            <div
                ref={menuRef}
                className={`fixed top-0 right-0 h-full w-3/4 sm:w-1/2 bg-white shadow-lg transition-transform duration-300 z-[200] ${
                    visible ? "translate-x-0" : "translate-x-full"
                }`}
            >
                <div className="flex flex-col h-full p-6 bg-white">
                    {/* Close Button */}
                    <div onClick={() => setVisible(false)} className="flex items-center gap-4 p-2 cursor-pointer">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-6 h-6 text-black"
                        >
                            <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5 8.25 12l7.5-7.5" />
                        </svg>
                        <p className="text-black text-lg">Close</p>
                    </div>

                    {/* Mobile Navigation */}
                    <nav className="mt-6 space-y-6 text-lg">
                        {navLinks.map((link, index) => (
                            <Link
                                key={index}
                                to={link.path}
                                className="block text-black hover:text-gray-800 transition-all p-3 rounded-md bg-white shadow-sm"
                                onClick={() => setVisible(false)}
                            >
                                {link.name}
                            </Link>
                        ))}
                    </nav>
                </div>
            </div>
        </div>
    );
};

export default Navbar;
