import React from 'react';
import { assets } from "../assets/assets";

const AboutComponent = () => {
  return (
    <div className="min-h-screen bg-gray-50">
      {/* Hero Section */}
      <div
        className="relative w-full h-32 md:h-40 bg-cover bg-center"
        style={{ backgroundImage: `url(${assets.aboutHero})` }}
      >
        <div className="absolute inset-0 bg-indigo-700 opacity-60"></div>
        <div className="relative flex items-center justify-center h-full">
          <h1 className="text-white text-4xl md:text-5xl font-bold uppercase">
            About Us
          </h1>
        </div>
      </div>

      {/* Introduction Section */}
      <div className="container mx-auto px-4 sm:px-8 lg:px-20 py-16">
        <div className="flex flex-col lg:flex-row items-center gap-8">
          <div className="lg:w-1/2">
            <img
              src={assets.aboutImg1}
              alt="About Us"
              loading="lazy"
              className="w-full h-96 object-cover rounded-lg shadow-lg"
            />
          </div>
          <div className="lg:w-1/2 text-gray-800">
            <h2 className="text-3xl font-bold uppercase mb-4">Who We Are</h2>
            <p className="text-lg leading-relaxed">
              The Indian Institute of Career Education (IICE) is a leading institution dedicated to bridging the gap between traditional education and modern industry demands. Focused on empowering students with cutting-edge skills, IICE specializes in Industry 4.0 technologies such as Robotics, Artificial Intelligence (AI), Internet of Things (IoT), Machine Learning, and 3D Printing. With affordable, hands-on programs designed to meet industry standards, IICE ensures students from diverse backgrounds gain practical knowledge and become job-ready professionals.
            </p>
          </div>
        </div>
      </div>

      {/* Vision & Mission Section */}
      <div className="bg-gradient-to-r from-teal-500 to-cyan-500 py-16">
        <div className="container mx-auto px-4 sm:px-8 lg:px-20 text-white">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <div className="flex flex-col justify-center">
              <h2 className="text-3xl font-bold uppercase mb-4">Our Vision</h2>
              <p className="text-lg leading-relaxed">
                To revolutionize education by integrating advanced technologies like AI and robotics with skill-based learning, preparing students to lead in a tech-driven world and shape a brighter future.
              </p>
            </div>
            <div className="flex flex-col justify-center">
              <h2 className="text-3xl font-bold uppercase mb-4">Our Mission</h2>
              <p className="text-lg leading-relaxed">
                To equip students with expertise in technology, AI, and robotics, alongside critical thinking and real-world skills, empowering them to excel academically and innovate for the future.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutComponent;
