import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { assets } from "../assets/assets";

export default function Carousel() {
  // Testimonial data – update these values and asset paths as needed
  const testimonials = [
    {
      id: 1,
      quote: "IICE changed my perspective on education. The hands-on approach truly makes a difference!",
      author: "John Doe",
      image: assets.img3,
    },
    {
      id: 2,
      quote: "A transformative experience that blends academics with real-world skills.",
      author: "Jane Smith",
      image: assets.img4,
    },
    {
      id: 3,
      quote: "The expert-led sessions prepared me for my career like no other program.",
      author: "Michael Johnson",
      image: assets.img5,
    },
    
  ];

  // Slick carousel settings
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      { breakpoint: 1024, settings: { slidesToShow: 2 } },
      { breakpoint: 768, settings: { slidesToShow: 1 } },
    ],
  };

  return (
    <div className="max-w-screen-lg mx-auto py-10 px-4">
      <h2 className="text-center text-3xl md:text-4xl font-semibold mb-8 text-gray-800">
        Testimonials
      </h2>
      <Slider {...settings}>
        {testimonials.map((testimonial) => (
          <div key={testimonial.id} className="p-4">
            <div className="bg-white rounded-lg shadow-lg p-6 flex flex-col items-center text-center transition-transform duration-300 hover:scale-105">
              <p className="text-gray-700 text-lg italic mb-4">
                "{testimonial.quote}"
              </p>
              <div className="flex items-center">
                <img
                  src={testimonial.image}
                  alt={testimonial.author}
                  className="w-12 h-12 rounded-full object-cover mr-4"
                />
                <div>
                  <p className="text-gray-900 font-semibold">{testimonial.author}</p>
                  <p className="text-gray-500 text-sm">Student</p>
                </div>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
}
