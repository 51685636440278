import React from 'react';

const ContactUs = () => {
    return (
        <div className="pt-32 pb-20 bg-orange-50 min-h-screen flex items-center justify-center">
            <section className="text-neutral-900 w-full max-w-6xl px-6 md:px-10">
                {/* Heading */}
                <h2 className="text-3xl sm:text-4xl font-bold text-center mb-8">Contact Us</h2>

                {/* Contact & Map Container */}
                <div className="bg-white p-6 md:p-10 rounded-2xl shadow-xl flex flex-col md:flex-row items-center md:items-start gap-8 transition-all">
                    
                    {/* Left Side: Contact Info */}
                    <div className="md:w-1/2 w-full space-y-5 text-center md:text-left">
                        <p className="text-lg text-gray-800">
                            <strong>Email:</strong> iice.skills@gmail.com
                        </p>
                        <p className="text-lg text-gray-800">
                            <strong>Support Email:</strong> supportiice@gmail.com
                        </p>
                        <p className="text-lg text-gray-800">
                            <strong>Phone:</strong> +91 78921 39002, +91 87890 29399
                        </p>
                        <p className="text-lg text-gray-800">
                            <strong>Address:</strong> Shampura Main Road, Near Ambedkar College, <br />
                            Bengaluru, Karnataka, India-560045
                        </p>
                    </div>

                    {/* Right Side: Map */}
                    <div className="md:w-1/2 w-full rounded-lg overflow-hidden shadow-lg border border-gray-300">
                        <iframe
                            title="Location"
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3890.058555237849!2d77.610438!3d13.0245247!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae170eb9cc981b%3A0xfc87f760959e8286!2sIndian%20Institute%20of%20Career%20Education!5e0!3m2!1sen!2sin!4v1707215623456!5m2!1sen!2sin"
                            width="100%"
                            height="300"
                            allowFullScreen
                            loading="lazy"
                            referrerPolicy="no-referrer-when-downgrade"
                            className="rounded-lg"
                        ></iframe>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default ContactUs;
