import img1 from "./Robotics1.png"
import img2 from "./medical1.png"
import img3 from "./robo2.png"
import img4 from "./medical2.png"
import img5 from "./robo3.png"
import img6 from "./graphic.png"
import img7 from "./graphicsicon.png"
import logo1 from "./robot (1).png"
import logo2 from "./ai-technology.png"
import logo3 from "./programming (1).png"
import logo4 from "./smartwatch.png"
import logo5 from "./skill.png"
import logo6 from "./medical-history.png"
import logo7 from "./printing.png"
import bg from "./space-7273891_1920.jpg"
import logoname0 from "./Indian Institute of Career Education.png"
import logoname1 from "./Indian Institute of Career Education-1.png"
import logoname2 from "./Indian Institute of Career Education-2.png"
import logoname3 from "./Indian Institute of Career Education-3.png"
import aboutImg1 from "./pexels-lilartsy-1236421.jpg"
import aboutImg2 from "./pexels-pixabay-163064.jpg"
import img11 from "./science-report.png"
import img12 from "./cyber-security.png"
import img13 from "./smartphone.png"
import img14 from "./drone.png"
import img16 from "./search-engine.png"






export const assets = {

    img1,
    img2,
    img3,
    img4,
    img5,
    img6,
    img7,
    logo1,
    logo2,
    logo3,
    logo4,
    logo5,
    logo6,
    logo7,
    bg,
    logoname0,
    logoname1,
    logoname2,
    logoname3,
    aboutImg1,
    aboutImg2,
    img11,
    img12,
    img13,
    img14,
    img16,

}