import React from 'react';
import { assets } from "../assets/assets";

const Courses = () => {
  const tools = [
    { src: assets.logo1, name: "Robotics" },
    { src: assets.logo2, name: "Artificial Intelligence" },
    { src: assets.logo3, name: "Computer Programming" },
    { src: assets.img13, name: "Internet of Things" },
    { src: assets.logo5, name: "Soft Skills" },
    { src: assets.logo6, name: "Medical Skills" },
    { src: assets.logo7, name: "3D Printing" },
    { src: assets.img12, name: "Cyber Security" },
    { src: assets.img11, name: "Data Science" },
    { src: assets.img14, name: "Drone Technology" },
    { src: assets.img16, name: "How it Works" },
  ];

  return (
    <section className="text-gray-600 body-font px-4 sm:px-6 lg:px-0">
      {/* Header outside of the card */}
      <div className="text-center my-20">
        <h2 className="text-black text-4xl md:text-5xl font-bold uppercase">
          COURSES WE PROVIDE
        </h2>
      </div>
      {/* Card with courses grid */}
      <div className="flex justify-center mb-8">
        <div className="bg-white shadow-xl rounded-lg p-8 w-full max-w-4xl lg:max-w-6xl">
          <div className="grid grid-cols-3 sm:grid-cols-4 gap-6 justify-items-center">
            {tools.map((tool, index) => (
              <div key={index} className="flex flex-col items-center">
                <div className="bg-gray-100 border border-gray-300 rounded-full w-32 h-32 flex flex-col items-center justify-center space-y-2 p-2">
                  <img src={tool.src} alt={tool.name} className="h-10" />
                  <p className="text-gray-700 text-sm text-center">{tool.name}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Courses;
