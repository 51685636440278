import React from "react";
import { Link } from "react-router-dom";
import linkedin from "../Images/linkedin (2).png";
import git from "../Images/github.png";
import x from "../Images/twitter (1).png";
import logo from "../Images/iicedarkjpg.png";
import logo2 from "../Images/lightlogoiice-removebg-preview.png";

export default function Footer() {
  return (
    <footer className="bg-[#437E6D] text-white py-10">
      <div className="container mx-auto px-6 md:px-10 flex flex-col md:flex-row justify-between gap-y-10">
        
        {/* Logo & Tagline */}
        <div className="w-full md:w-1/4 flex flex-col items-center md:items-start">
          <img src={logo2} alt="iice logo" className="w-20 h-20 mb-3" />
          <p className="text-sm text-center md:text-left">
            Empowering Young Minds for a <br /> Future-Ready World ──
          </p>
        </div>

        {/* Quick Links */}
        <div className="w-full md:w-1/4 flex flex-col items-center md:items-start">
          <h2 className="text-lg font-semibold mb-3">QUICK LINKS</h2>
          <nav className="space-y-2 text-center md:text-left">
            <Link to="/" className="hover:text-gray-300 pr-2">Home</Link>
            <Link to="AboutUs" className="hover:text-gray-300 px-2">Company</Link>
            <Link to="AboutUs" className="hover:text-gray-300 px-2">About Us</Link>
            <Link to="ContactUs" className="hover:text-gray-300 pl-2">Contact</Link>
          </nav>
        </div>

        {/* Contact Info */}
        <div className="w-full md:w-1/4 flex flex-col items-center md:items-start">
          <h2 className="text-lg font-semibold mb-3">CONTACT</h2>
          <p className="text-sm text-center md:text-left leading-relaxed">
            <strong>Email:</strong> iice.skills@gmail.com <br />
            <strong>Phone:</strong> +91 78921 39002, +91 87890 29399 <br />
            <strong>Address:</strong> Shampura Main Road, Near Ambedkar College, Bengaluru, Karnataka, India-560045
          </p>
        </div>

        {/* Social Media */}
        <div className="w-full md:w-1/4 flex flex-col items-center md:items-start">
          <h2 className="text-lg font-semibold mb-3">FOLLOW US</h2>
          <div className="flex space-x-4">
            <img
              src={linkedin}
              alt="LinkedIn"
              className="w-8 h-8 cursor-pointer transition transform hover:scale-110"
              onClick={() => window.open("https://www.linkedin.com/company/indian-institute-of-career-education/", "_blank")}
            />
            <img
              src={git}
              alt="GitHub"
              className="w-8 h-8 cursor-pointer transition transform hover:scale-110"
              onClick={() => window.open("https://github.com", "_blank")}
            />
            <img
              src={x}
              alt="Twitter"
              className="w-8 h-8 cursor-pointer transition transform hover:scale-110"
              onClick={() => window.open("https://twitter.com", "_blank")}
            />
          </div>
        </div>
      </div>

      {/* Copyright */}
      <div className="mt-8 text-center text-sm opacity-80">
        <p>© 2025 iice.co.in | All rights reserved.</p>
      </div>
    </footer>
  );
}
