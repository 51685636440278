import React from 'react';
import { useNavigate } from 'react-router-dom';
import { assets } from '../assets/assets';

export default function Banner() {
    const navigate = useNavigate();

    const handleButtonClick = () => {
        navigate('/carts');
    };

    // FeatureCard component to display each feature with a logo icon.
    const FeatureCard = ({ icon, title, description }) => {
        return (
            <div className="bg-white bg-opacity-90 backdrop-blur-sm rounded-xl shadow-lg p-6 text-center hover:shadow-2xl transition duration-300">
                <div className="flex justify-center mb-4">
                    {/* Display logo icon from assets */}
                    <img src={icon} alt={title} className="w-8 h-8" />
                </div>
                <h3 className="text-xl font-semibold text-gray-800 mb-2">{title}</h3>
                <p className="text-gray-600">{description}</p>
            </div>
        );
    };

    // Why IICE component renders a section with feature cards.
    const WhyIICE = () => {
        const features = [
            {
                title: "Practical Learning",
                description: "Hands-on experience beyond textbooks",
                icon: assets.logo1,
            },
            {
                title: "Skill-Based Curriculum",
                description: "Focus on real-world applications",
                icon: assets.logo2,
            },
            {
                title: "Expert-Led Sessions",
                description: "Industry professionals and educators",
                icon: assets.logo3,
            },
            {
                title: "Future-Ready Approach",
                description: "Preparing students for modern careers",
                icon: assets.logo4,
            },
        ];

        return (
            <div className="py-16 bg-gradient-to-r from-teal-500 to-cyan-500 ">
                <div className="container mx-auto px-40">
                    <div className="text-center mb-12">
                        <h2 className="text-4xl md:text-5xl font-extrabold text-white">
                            Why IICE?
                        </h2>
                        <p className="mt-4 text-lg text-teal-200">
                            Empowering education for a brighter future.
                        </p>
                    </div>
                    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8">
                        {features.map((feature, index) => (
                            <FeatureCard
                                key={index}
                                icon={feature.icon}
                                title={feature.title}
                                description={feature.description}
                            />
                        ))}
                    </div>
                </div>
            </div>
        );
    };
}
